@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .fill-primary {
    fill: theme("colors.primary.500");
  }
  .fill-secondary {
    fill: theme("colors.secondary.500");
  }
  .fill-light {
    fill: theme("colors.light");
  }
  .fill-dark {
    fill: theme("colors.dark");
  }
}
